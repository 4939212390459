// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-position-relative {
    position: relative;
  }
  
  .custom-password-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #6c757d;
    cursor: pointer;
    z-index: 10;
    font-size: 15px;
  }`, "",{"version":3,"sources":["webpack://./src/components/ChangePassword/ChangePassword.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,2BAA2B;IAC3B,cAAc;IACd,eAAe;IACf,WAAW;IACX,eAAe;EACjB","sourcesContent":[".custom-position-relative {\n    position: relative;\n  }\n  \n  .custom-password-icon {\n    position: absolute;\n    right: 15px;\n    top: 50%;\n    transform: translateY(-50%);\n    color: #6c757d;\n    cursor: pointer;\n    z-index: 10;\n    font-size: 15px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
