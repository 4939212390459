// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .input-group-text i {
    font-size: 16px;
    color: #6c757d;
  }
  .input-group-text:hover i {
    color: #F55570;
  }
  
  input:focus, select:focus, textarea:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: #fd8f2f !important;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Users/AddUsers.css"],"names":[],"mappings":";EACE;IACE,eAAe;IACf,cAAc;EAChB;EACA;IACE,cAAc;EAChB;;EAEA;IACE,wBAAwB;IACxB,2BAA2B;IAC3B,gCAAgC;EAClC","sourcesContent":["\n  .input-group-text i {\n    font-size: 16px;\n    color: #6c757d;\n  }\n  .input-group-text:hover i {\n    color: #F55570;\n  }\n  \n  input:focus, select:focus, textarea:focus {\n    outline: none !important;\n    box-shadow: none !important;\n    border-color: #fd8f2f !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
