// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-input-edit-profile {
    border: none;
    border-bottom: 1px solid #333;
    background: transparent;
    outline: none;
    box-shadow: none;
    border-radius: 0;
}

.custom-input-edit-profile:focus {
    border-bottom: 2px solid #000;
    outline: none;
    box-shadow: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Profile/EditProfile.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,6BAA6B;IAC7B,uBAAuB;IACvB,aAAa;IACb,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,6BAA6B;IAC7B,aAAa;IACb,gBAAgB;AACpB","sourcesContent":[".custom-input-edit-profile {\n    border: none;\n    border-bottom: 1px solid #333;\n    background: transparent;\n    outline: none;\n    box-shadow: none;\n    border-radius: 0;\n}\n\n.custom-input-edit-profile:focus {\n    border-bottom: 2px solid #000;\n    outline: none;\n    box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
